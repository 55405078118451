.bandeau {
  background-color: #209cee !important;
  padding: 1.25rem .75rem 1.25rem 1.5rem;
  border-radius: 0;
  color: #FFF !important;
  margin-top: 64px;
}
.button-change-office {
  height: 18px !important;
}

.dialog-change-office {
  min-width: 350px;
}

@media screen and (max-width: 980px) {
  .bandeau {
    margin-top: 0;
  }
}
