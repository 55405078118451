.brand {
  background:url("../../../assets/images/logoPimms.png") no-repeat;
  min-width: 225px;
  height: 64px;
  background-size: cover;
  margin-right: 1rem;
  cursor: pointer;
}

@media screen and (max-width: 980px) {
  .brand {
    margin-right: 2rem;
  }
}

.button-nav {
  color: #595959 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}

.button-nav:hover {
  color: #1b4f9c !important;
}

.menuNavLink.active > .button-nav {
  color: #1b4f9c !important;
}


.menu-container, .menu-divider {
  height: 48px !important;
}


.mobile-menu-button {
  position: absolute !important;
  top: 1rem;
  color: white !important;
}
