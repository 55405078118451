@import '../../../node_modules/@fortawesome/fontawesome-svg-core/styles.css';

@font-face {
    font-family: 'Dosis';
    src: url(../../assets/fonts/Dosis-VariableFont_wght.ttf) format('truetype');
}

* {
    font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif !important;
}

a {
    color: #209cee;
    text-decoration: none;
}

body {
    color: #4a4a4a;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
}

.logo_unpimms {
    background: url("../../assets/images/logoPimms.png");
    width: 417px;
    height: 112px;
    background-size: contain;
    /*border-radius: 50%;*/
}

.button-progress {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -12px;
}

.form-error {
    color: #CA0007 !important;
    font-size: smaller !important;
    font-style: italic !important;
}

.MuiButton-label {
    text-transform: none;
}

.App {
    position: relative;
    min-height: calc(100vh - 64px); /*-sticky header height*/
}

.App-wrapper {
    padding-bottom: 96px; /*footer margin*/
}

.pageContainer {
    position: relative;
}

.select-dense {
    height: 40px;
    margin-top: 16px;
}

.form-select {
    margin-bottom: 8px !important;
}

.form-select > label:not(.MuiFormLabel-filled) {
    margin-top: 8px;
    height: 40px;
}

.asterix {
    color: #CA0007 !important;
}

.tableCell-xs {
    width: 75px !important;
}

.tableCell-sm {
    width: 175px;
}

.tableCell-md {
    width: 250px;
}

.tableCell-noPadding {
    padding: 0 !important;
}

.tableCell-noPaddingRight {
    padding-right: .5rem !important;
}

.tableCell-noPaddingLeft {
    padding-left: .5rem !important;
}

.tableCell-clickable {
    cursor: pointer;
}

.MuiTableRow-hover:hover {
    background: rgba(211, 211, 211, 0.5) !important;
}


.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.sectionContainer {
    margin-top: 1rem;
    border: 1px solid #23a23e;
    border-radius: 5px;
    padding: 1rem;
}

.sectionHeader {
    margin-bottom: 1rem;
    font-size: xx-large;
    color: #23a23e;
    text-transform: uppercase;
}

.sectionSubheader {
    margin-bottom: 1rem;
    margin-top: 2rem !important;
    font-size: x-large;
    color: #276D8C;
}

.sectionHeader:not(:first-child) {
    margin-top: 2.5rem;
}

.selectFieldContainerOperator,
.selectFieldContainerService {
  min-height: 64px;
  padding: 0 16px !important;
  flex-basis: -moz-available !important;
}

.selectFieldContainer {
    min-height: 64px;
    padding: 0 16px !important;
}

.selectDeleteIcon {
    padding-right: .5rem;
    width: .8rem !important;
}

.uppercase {
    text-transform: uppercase;
}

.rct-node-icon {
    color: #23a23e !important;
}

.rct-collapse.rct-collapse-btn {
    width: 24px !important; /* fix height bug with custom icon */
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
}


.mt-0 {
    margin-top: 0 !important;
}

.mt-1 {
    margin-top: 1rem;
}

.mt-2 {
    margin-top: 2rem !important;
}

.mb-1 {
    margin-bottom: 1rem !important;
}

.mb-2 {
    margin-bottom: 2rem !important;
}

.pl-1 {
    padding-left: 1rem;
}

.pl-2 {
    padding-left: 2rem;
}

.pl-3 {
    padding-left: 3rem;
}

.pl-4 {
    padding-left: 4rem;
}

.pl-5 {
    padding-left: 5rem;
}

.pt-1 {
    padding-top: 1rem !important;
}

.pt-2 {
    padding-top: 2rem !important;
}

.pr-1 {
    padding-right: 1rem;
}

.pr-2 {
    padding-right: 2rem;
}

.pr-3 {
    padding-right: 3rem;
}

.pr-4 {
    padding-right: 4rem;
}

.pr-5 {
    padding-right: 5rem;
}


g[aria-labelledby] {
    visibility: hidden;
}

@media screen and (max-width: 980px){
    .MuiSelect-select.MuiSelect-select {
        padding-right: 0 !important;
    }
    .MuiSlider-markLabel {
        display: none;
    }
    .MuiSlider-markLabelActive {
        display: block;
    }
}


span.filetitle {
    font-style: italic;
    font-weight: bolder;
}

.report-nodata {
    text-align: center;
    font-style: italic;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover {
    background-color: rgba(35, 163, 62, 0.8) !important;
}

.row-disabled {
    background-color: #b8b8b8 !important;
}
